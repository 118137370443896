export const socialLinks = [
  { icon: "fab fa-github", link: "https://github.com/designersingh" },
  {
    icon: "fab fa-linkedin-in",
    link: "https://www.linkedin.com/in/designersingh/",
  },
  {
    icon: "fab fa-instagram",
    link: "https://www.instagram.com/uday_singh_nihung_singh/",
  },
];

export const experiences = [
  {
    badgeClass: "info",
    company: "Intuit",
    duration: "Sept 2021 – Present",
    badgeIconClass: "fa fa-bookmark",
    title: "Senior Software Engineer",
    content: ``,
  },
  {
    badgeClass: "info",
    company: "Qovato",
    duration: "Jan 2021 – May 2021",
    badgeIconClass: "fa fa-bookmark",
    title: "Founder/CEO",
    content: `Qovato provides the technology Small Businesses need to succeed in today's competitive online space. By developing a modern, beautifully designed, easy to use, platform, we strive to offer a great variety of options to anyone who wants to start selling online without spending a lot of time and money while maintaining their brand identity.`,
  },
  {
    badgeClass: "info",
    company: "Homesome",
    duration: "Feb 2020 – Nov 2020",
    badgeIconClass: "fa fa-bookmark",
    title: "Senior Full Stack Developer",
    content: `First Full Stack Engineer. Bootstrapped the platform from ground up (wrote first line of code). Responsible for defining design language, UI architecture, custom build systems, REST api design from ground up using Angular, Node.js, bootstrap, material design etc. Responsible for delivering features from design to finished product. Being first engineer on-board helped defined the development cycle adopting best practices for delivering robust features in a timely fashion to strengthen the platform.`,
  },
  {
    badgeClass: "info",
    company: "PeerNova",
    duration: "May 2018 – Oct 2019",
    badgeIconClass: "fa fa-bookmark",
    title: "Lead Full Stack Developer",
    content: `Lead a team of developers for one of the consumer facing products. Leveraged Angular and Node.js on a daily basis to fulfill product requirements. I worked with product and customer success to deliver robust product features to our customers.`,
  },
  {
    badgeClass: "info",
    company: "EventJoin",
    duration: "Sep 2017 – Mar 2018",
    badgeIconClass: "fa fa-bookmark",
    title: "Advisory / Head of UI Engineering",
    content: `I lead Design and UI engineering at EventJoin, responsible for defining/maintaining design language and UI architecture along with User Experience.`,
  },
  {
    badgeClass: "info",
    company: "NatureCare Box",
    duration: "Jun 2017 – Oct 2017",
    badgeIconClass: "fa fa-bookmark",
    title: "Co-Founder/CTO",
    content: `Men's beard & skin care products delivered to your door. All Natural - High Quality - Exclusive.`,
  },
  {
    badgeClass: "info",
    company: "Pley",
    duration: "Oct 2016 – Dec 2017",
    badgeIconClass: "fa fa-bookmark",
    title: "Senior Front-End Developer",
    content: `• Developed responsive single-page web application for subscription toybox from ground up; key technologies were: AngularJs, REST API, Bootstrap<br> • Made interactive front end components with AngularJS and vanilla javascript.<br>• Set up build (concat, minify etc.) and testing pipelines with Gulp<br>• Created responsive mobile-first markup with CSS and Bootstrap<br>•  Made performance and bandwidth optimization of web application leveraging Angular caching, JavaScript and CSS code load time optimization with UglifyJs, Concat<br>• Communicated to product managers to create clean and intuitive interfaces<br>•  Social Media Integration with Facebook, Twitter<br>• Leading a team of junior developers to help deliver the pixel perfect products while maintaining code standard and scalability.`,
  },
  {
    badgeClass: "info",
    company: "Pley",
    duration: "Jan 2015 - Oct 2016",
    badgeIconClass: "fa fa-bookmark",
    title: "UI/Front-End Developer",
    content: `• Responsible for product UI from scratch and creating prototypes. <br/>• Brainstorm and implement UI solutions for various projects and multiple teams both onsite and remote <br/>• Initiated User research, Usability and Concept testing <br/>• Created UI sketches, flows, wireframes and rapid prototypes for Web and Mobile platforms <br/>• Worked on different projects including built up from scratch <br/>• Used a wide variety of software to complete the projects including Photoshop, Illustrator and coding such as HTML5, CSS3, JavaScript / jQuery`,
  },
  {
    badgeClass: "info",
    company: "Ushur.me",
    duration: "Mar 2014 - Aug 2014",
    badgeIconClass: "fa fa-bookmark",
    title: "Lead UI/UX Developer",
    content: `• Responsible for product UI from scratch and creating prototypes. <br/>• Brainstormed and implemented UI solutions for various projects and multiple teams both onsite and remote <br/>• Initiated User research, Usability and Concept testing <br/>• Created UI sketches, flows, wireframes and rapid prototypes for Web and Mobile platforms <br/>• Worked on different projects including built up from scratch <br/>• Used a wide variety of software to complete the projects including Photoshop, Illustrator and coding such as HTML5, CSS3, JavaScript / jQuery`,
  },
  {
    badgeClass: "info",
    company: "Khalis Foundation",
    duration: "May 2013 - Feb 2014",
    badgeIconClass: "fa fa-bookmark",
    title: "Lead UI/UX Developer",
    content: `<b> Lead the UX/UI Development for company's various products. My job responsibilities include </b> <br/>• Responsible for product UI from scratch and creating prototypes. <br/>• Brainstormed and implemented UI solutions for various projects and multiple teams both onsite and remote <br/>• Collaborated with Visual Designers and Content Strategists <br/>• Created UI sketches, flows, wireframes and rapid prototypes for Web and Mobile platforms <br/>• Used a wide variety of software to complete the projects including Photoshop, Illustrator and coding such as HTML5, CSS3, JavaScript / jQuery <br/>• Completed all the projects within deadline`,
  },
  {
    badgeClass: "info",
    company: "Dazzle5 Design Studio",
    duration: "Aug 2010 - Apr 2013",
    badgeIconClass: "fa fa-bookmark",
    title: "UI/UX/Front-End Developer",
    content: `• Provide user interface design for Web-based projects, including dashboards, widgets, and products. <br/>• Develop HTML prototypes and UI deliverables, such as wireframes, flowcharts, screen mock-ups, and interface design specifications. <br/>• Assist with walk-throughs and usability testing. Update UI per changing needs and requirements. <br/>• Publishing: newsletters, magazines, books <br/>• Marketing: direct mail, brochures, conference materials, circulation, stationery, and identity/branding <br/>• Advertising: small- and big-budget ads designed and placed in magazines and newspapers <br/>• Creating the layout for documentation and supporting print media with graphics and illustrations. <br/>• Meeting deadline driven projects that require strong multi-tasking skills, client and vendor coordination, and the ability to juggle shifting priorities.`,
  },
  {
    badgeClass: "info",
    company: "Bir Infosys",
    duration: "Mar 2008 - June 2010",
    badgeIconClass: "fa fa-bookmark",
    title: "Jr. Web Developer",
    content: `<b>UX/UI design and development for company's various clients. My job responsibilities include </b> <br/>• Responsible for product UI from scratch and creating prototypes. <br/>• Hand code HTML, CSS , jQuery <br/>• Basic PHP for dynamic page implementation <br/>• Implemented multiple CMS platform websites <br/>• Developed multiple organic jQuery content sliders <br/>• Basic Search Engine Optimization, content writing`,
  },
  {
    badgeClass: "info",
    company: "Apeejay College of Fine Arts",
    duration: "Mar 2007 - June 2013",
    badgeIconClass: "fa fa-graduation-cap",
    title: "Bachelors in MultiMedia",
    content: "",
  },
];

export const recommendations = [
  {
    name: "Matthew Mishler",
    title: "Jr Software Developer at PeerNova Inc",
    group: "Matthew reported directly to Tajinder Pal",
    text: `I had the pleasure of working with and mentored by TJ for a little over a year at Peernova Inc.  TJ is the very definition of a Fullstack Developer. Not only is he a CSS guru, but he possesses a deep knowledge in a wide variety of topics including: Angular, HTML, Node, Express and many others.<br><br>

            While his technical prowess speaks for itself, TJ's truest value is in his ability to fit seamlessly within his team and company. From the moment I first met and was interviewed by TJ, he was friendly, approachable and became a true friend. He possesses the ability to treat any and all members with respect and as equals and colleagues. When times get tough, TJ is the one you turn to, to rally the team to pull their socks up and get the job done. <br><br>
            
            I valued his work and guidance immensely and he will be a key asset in any and all future ventures.`,
  },
  {
    name: "Laith AlQuran",
    title: "Software Engineer at Google",
    group: "Tajinder Pal worked with Laith in the same group",
    text: `I worked with TJ for about a year at PeerNova. From the moment he interviewed me, I knew that he was an absolute coding savant. TJ has a deep grasp of all web-development concepts and it is routinely apparent in all of his work. TJ led the team as we implemented projects using Angular, HTML, CSS, Node, Express, and many third party libraries. Not only does he implement his own tasks in a very efficient manner, but is a fantastic mentor as well. TJ always makes time to assist the junior developers as they come across any roadblocks. He explains concepts very well, is extremely motivating, and always presents his ideas in a kind, positive manner. Overall, he is a fantastic asset to any development team as he is very knowledgable, a great leader, is very personable, and shows exceptional work ethic.`,
  },
  {
    name: "Calvin Nguyen",
    title: "Full Stack Developer",
    group: "Calvin worked with Tajinder Pal in different groups",
    text: `I've worked with Tajinder (T.J) for about more than a year. From the moment I interviewed him, I know he is a very good Full-Stack developer. He knows the technologies well and able to communicate clearly, which is his major strength. T.J. has a great personality as well and very likable within the team &amp; office. We always have plenty of laughter when he's around. He was a team lead at PeerNova and even though I did not work with him directly as we worked in different teams, I can see he has potential to be a great manager. I've witnessed his design skills and they are amazing and very professional. T.J. is someone who can learn quick and works well within the team. He'll be a great asset to any company. He was an awesome co-worker and is still a good friend to me.`,
  },
  {
    name: "Cody Bentson",
    title: "Software Engineer at Ticketmaster",
    group: "Cody reported directly to Tajinder Pal",
    text: `I had the honor of working on TJ’s team for the last ~16 months. It was clear after only a few weeks of working with him that he is a Swiss Army Knife of a Web Developer. JavaScript, CSS, Responsive Design, HTTP, Node.. you name it, he can do. <br><br>

            What makes TJ stand out is that he is more than just a great developer. TJ is a great person! He treats people with decency and respect. He’s there for his coworkers during the good times and bad. TJ would make an outstanding addition to anyone looking for a great developer and even better person to join their team.`,
  },
  {
    name: "Anurag Phadke",
    title: "Founder at WebFives.com",
    group: "Anurag managed Tajinder Pal directly",
    text: `Tajinder is an extremely talented and hard-working individual. His talents range from building amazing UX experiences, to pixel perfect mocks, to beautiful responsive designs.<br><br>
            He has played a critical role in building Pley and PleyWorld on stringent timelines and has always delivered on-time. He is extremely well-versed in Javascript (AngularJS / node), understands different design patterns, algorithms and loves to don engineering hat on-regular basis. At Pley, more often than I can remember, he has taken something that we discussed on paper to a fully functioning solution in matter of days. TJ is an amazing asset to Pley!`,
  },
  {
    name: "Shailesh Modi",
    title: "Head of Products/ Director, Product Management and strategy",
    group: "Shailesh was senior to Tajinder Pal but didn’t manage directly",
    text: `We hired Tajinder for the UI/UX developer at very critical time and he started delivering from the day one!! <br><br>

            Tajinder is one of the fastest UI/UX developer, I have seen. At the same time, he is very innovative, agile and practical.  Tajinder understood the whole UI/UX stack at Pley very quickly and built some of the very cool features on the Web and mobile UI. <br><br>
            
            Tajinder has a very positive attitude towards work and he is fun to work with. <br>
            
            I will hire him any day!!`,
  },
  {
    name: "Alejandro Salazar",
    title: "Sr. Software Engineer at Intuit",
    group: "Alejandro was senior to Tajinder Pal but didn’t manage directly",
    text: `Tajinder and I have worked closely since he joined Pley, since the very beginning he demonstrated optimism and skill.<br><br>

            Tajinder is a very dedicated Professional, involved in many of the aspects involving Front End development, Think of the most friendly and usable User Experience, then Designs and implements.<br>
            He is one of the few professionals that has a great mix of development and design.<br><br>
            
            Tajinder is the kind of person that loves to learn and push himself to achieve great things, whether it is at work or in his personal life.<br><br>
            
            I'm fortunate to have crossed paths with Tajinder and looking forward to do more projects together.`,
  },
  {
    name: "Sherry Willhoite",
    title: "Product Management",
    group: "Tajinder Pal worked with Sherry in the same group",
    text: `Tajinder has proven himself to be a consistent rockstar and key contributor to every project I've worked on at Pley. He possesses the killer combo of UI design and deep well-rounded front-end development skills. He's very solution oriented and one of the most efficient people I've worked with in my career. We hit all of our deadlines and he regularly over-delivers with an added layer of polish to every project. Tajinder always strives to understand and embrace the latest technologies and has built out a streamlined framework that allows us to generate front-end changes super efficiently. Beyond all of that, he's also just a joy to work with and tackles last minute changes and on-the-fly tweaks all with a smile still on his face. He's a person you'd be lucky to have on your team as he's a fantastic and skilled worker and an even better person and teammate.`,
  },
  {
    name: "Harjap Kaur",
    title: "Platform Architect at Intel",
    group: "Harjap worked with Tajinder Pal but at different companies",
    text: `I have worked with Tajinder on some volunteer Sikh projects. Tajinder is a very talented, detail-oriented designer. I have personally seen some of his work and am impressed by his elegant, user-centric approach to design. He is passionate about his work and always keen on learning new technologies, skills and tools. <br><br>

            If any opportunity arises in future I would love to work with him again. With his skills and experience, I strongly recommend Tajinder for any suitable UI/UX design projects/position.`,
  },

  {
    name: "Alexei Sorokine",
    title: "Software Engineer",
    group: "Tajinder Pal was senior to Alexei but didn’t manage directly",
    text: "In a short period that I had a chance to work with Tajinder, I found him to be very energetic and hard working. Not only he is skilled in front and back end technologies, but also has a good eye for design and layout. Tajinder can focus on the important and get things done quickly. He is open minded and fun to work with.",
  },
  {
    name: "Soheil Mazaheri",
    title: "Lead DevSecOps Engineer",
    group: "Soheil worked with Tajinder Pal in different groups",
    text: `I had the pleasure of working with Tajinder (T.J) for one year at PeerNova. He is very professional and polite. He is an excellent communicator who keep very professional and smooth relationship with his colleague.<br><br>
            I highly recommend TJ and I believe he would be a great addition to any company.`,
  },
  {
    name: "Greg Dalfonso",
    title: "Software Engineer",
    group: "Greg worked with Tajinder Pal in different groups",
    text: `I worked with TJ for over a year. During this time he demonstrated strong leadership skills, exceptional knowledge of Angular, Typescript &amp; CSS and outstanding design skills. I enjoyed collaborating with him and his team on multiple occasions and I hope to someday work with him again. I believe TJ would be a great asset to any company looking for a strong Lead/Full Stack Developer.`,
  },
  {
    name: "Sergey Golovko",
    title: "Back-end Developer",
    group: "Sergey was senior to Tajinder Pal but didn’t manage directly",
    text: `I worked with Tajinder for 1year+ and had a very positive impression about his professional skills and experience in the front-end development. He was able to organize the development process inside his team and successfully to communicate to the back-end team. Tajinder is a very polite person who is able to build and keep very good and smooth relationships with his coworkers.`,
  },
  {
    name: "Paul Vinuelas",
    title: "COO at X Delivery",
    group: "Paul was senior to Tajinder Pal but didn’t manage directly",
    text: `I worked with Tajinder for a little over a year and half and have been very impressed with his ability to juggle development projects as well as beautiful design work all while setting realistic deadlines. It is a pleasure to work with an engineer who has the ability to get things done while also being able to speak to the business leaders directly and not get lost in translation.`,
  },
  {
    name: "Andrea Chen",
    title: "Design Manager at BetterHelp.com",
    group: "Tajinder Pal worked with Andrea in the same group",
    text: `I could not have asked for a better desk-buddy than Tajinder. He is one of the most warm and cheerful people at Pley, and is always willing to lend a hand. He's helped me out of a bind more times than I can count, and I am super thankful that we have had the chance to work together. Tajinder handles pressure like a pro and always meets his deadlines. Moreover, he is constantly broadening his skill set and wanting to learn more. He would be great to have at any company (although he will only have one favorite desk-buddy and that's me).`,
  },
  {
    name: "Simha Sadasiva",
    title: "Co-Founder & CEO at Ushur",
    group: "Simha managed Tajinder Pal directly",
    text: `We hired Tajinder to create UI/UX for Ushur's various web workflows.Tajinder did an outstanding job in delivering our requirements. He has a great sense of design, and is very aware of the latest UI and UX trends. More importantly he understood our product and business needs. He created wireframes, HTML/CSS and JS that highlights user centered design for the best user experience, besides helping us design our marketing and sales collaterals. If you need more details on his work at Ushur, I invite you to write to me as a few words here don't do full justice to his contributions.`,
  },
];

export const skills = [
  _createSkillObj(
    "fa fa-code",
    "Javascript",
    "JavaScript is everywhere. It has evolved along with the Web and now finds itself entrenched in modern browsers, complex <b>Web applications, mobile development, server-side programming,</b> and in emerging platforms like the <b>Internet of Things</b>."
  ),
  _createSkillObj(
    "fa fa-sitemap",
    "Node.js",
    "I simply love <b>Node.js</b>. I provide help in the full lifecycle of JavaScript applications: design, development, code-reviews, deployment and maintenance."
  ),
  _createSkillObj(
    "fab fa-html5",
    "HTML5 & CSS3",
    "HTML5 semantics, HTML5 apis - geolocation, localstorage, canvas, audio and video - I have experience with all of these."
  ),
  _createSkillObj(
    "fa fa-rocket",
    "Web APIs",
    "Analytics - google analytics, Lucky Orange, Hotjar etc, social media integration - facebook, linkedIn, yelp and twitter - and maps integration are some other apis that we have integrated into existing client applications."
  ),
  _createSkillObj(
    "fa fa-server",
    "Build & Deployment",
    "I build automation in large projects using complex build processes with tools like gulp, grunt and tools like npm, bower and lots more."
  ),
];

function _createSkillObj(icon: string, title: string, desc: string) {
  return {
    icon,
    title,
    desc,
  };
}
