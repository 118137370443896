import React from "react";
import loading from "../../../assets/loading.svg";
import "./Loading.scss";

function Loading() {
  return (
    <div id="loading">
      <img src={loading} alt="loading" width="100" />
    </div>
  );
}

export default Loading;
