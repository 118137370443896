import React, { lazy, Suspense, useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";

import "./App.scss";
import Header from "./shared/components/Header/Header";
import Skills from "./shared/components/Skills/Skills";
import Loading from "./shared/components/Loading/Loading";

export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <>
      <ScrollToTop />
      <Header />
      <main className="main-view">
        <Switch>
          <Suspense fallback={<Loading />}>
            <Route
              exact
              path="/"
              component={lazy(() => import("./pages/Home/Home"))}
            />
            <Route
              path="/about"
              component={lazy(() => import("./pages/About/About"))}
            />
            <Route
              path="/recommendations"
              component={lazy(
                () =>
                  import("./shared/components/Recommendations/Recommendations")
              )}
            />
            <Route
              path="/contact"
              component={lazy(() => import("./pages/Contact/Contact"))}
            />
            {/*<Route path="*">
              <Redirect to="/" />
            </Route>*/}
          </Suspense>
        </Switch>
        <Skills />
      </main>
    </>
  );
}

export default App;
