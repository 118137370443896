import { NavLink } from "react-router-dom";
import "./Header.scss";
import logo from "../../../assets/singh-logo.svg";

function Header() {
  return (
    <nav
      className={`navbar navbar-expand-md navbar-dark fixed-top my-0 py-0 justify-content-between px-2`}
      id="header"
    >
      <NavLink to="/" className="navbar-brand py-2 px-2">
        <img
          src={logo}
          height="50"
          width="130"
          className="d-inline-block"
          alt="Logo"
        />
      </NavLink>

      <button
        className="navbar-toggler collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>

      <div
        className="navbar-collapse collapse justify-content-end"
        id="navbarCollapse"
      >
        <ul className="navbar-nav mr-2">
          <li className="nav-item">
            <NavLink activeClassName="active" to="/about" className="nav-link">
              About
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              activeClassName="active"
              to="/recommendations"
              className="nav-link"
            >
              Recommendations
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              activeClassName="active"
              to="/contact"
              className="nav-link"
            >
              Contact
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Header;
