import { skills } from "../../constants/data";
import "./Skills.scss";

function Skills() {
  return (
    <>
      <section className="container-fluid bg-all-dark py-2 waves">
        <div className="container pleftright0">
          <div className="row">
            <div className="col-12 text-left">
              <h1 className="mb-5 text-white">Skills</h1>
            </div>
          </div>

          <div className="row justify-content-center">
            {skills.length &&
              skills.map((skill, i) => {
                return (
                  <div
                    className="col-lg-4 col-sm-6 col-12 skill mb-sm-5"
                    key={i}
                  >
                    <i
                      className={`fa-2x text-primary mt-3 mb-4 ${skill.icon}`}
                    />
                    <h2 className="text-white font-weight-lighter">
                      {skill.title}
                    </h2>
                    <p
                      className="font-weight-lighter"
                      dangerouslySetInnerHTML={{ __html: skill.desc }}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </section>

      <section className="container-fluid bg-extreme-dark text-white py-2">
        <div className="row">
          <div className="col-12 d-flex p-3 justify-content-center align-items-center align-content-center">
            <h5 className="m-0 text-300">
              Made with
              <i className="fa fa-heart fa-1x animated infinite pulse text-danger px-2" />
              by Singh
            </h5>
          </div>
        </div>
      </section>
    </>
  );
}

export default Skills;
